@import "../../themes/generated/variables.base.scss";

.tooltip {
    font-size: .85em;

    .tooltip-title {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        padding: 14px;
        border-bottom: 1px solid #eee;

        .tooltip-title-date {
            flex: 1;
            display: flex;
            flex-direction: column;
            font-weight: bold;
            color: $base-accent;

            .tooltip-title-date__day {
                font-size: 1.75em;
            }

            .tooltip-title-date__month {
                font-size: 0.75em;
            }
        }

        .tooltip-title-info {
            flex: 3;
            display: flex;
            flex-direction: column;
            text-align: left;

            .tooltip-title-info__type {
                display: inline-block;
                font-size: 1.3em;
                margin-bottom: 3px;
            }

            .tooltip-title-info__busy {
                color: #8e8e8e;
            }
        }
    }

    .tooltip-content {
        margin: 14px;
        color: #666;

        .tooltip-content-booking {
            margin-top: 14px;

            .tooltip-content-booking__title {
                padding-bottom: 3px;
            }

            .tooltip-content-booking__date {
                color: #8e8e8e;
            }
        
            .tooltip-content-booking__date-div {
                margin: 0 10%;
                color: #9a9a9a;
            }
        }

        .tooltip-content__more {
            color: #ddd;
            position: absolute;
            right: 15px;
            top: 140px;
            font-size: 40px;
        }
    }
}