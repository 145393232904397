.header {
  margin-bottom: 30px;

  .title {
    line-height: 28px;
    font-weight: 500;
    font-size: 24px;
  }
}

.filter-card {
  padding: 1rem;
  margin-bottom: 1% !important;
}