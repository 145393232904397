.alert-container {
    padding: 60px 40px;
    text-align: center;

    .alert-svg {
        width: 200px;
        height: 200px;
    }

    .alert-message {
        max-width: 400px;
        margin: 48px auto 16px;
    }

    .alert-button {
        background-color: #8e8e8e;
        color: white;
        display: inline-block;
        text-decoration: none;
        text-transform: uppercase;
        padding: 8px 16px;
        border-radius: 50px;
        font-size: .8rem;
    }
}