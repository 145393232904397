.sms-text-box .dx-button {
  width: 32px !important;
  height: 32px !important;
  padding: 0px !important;
}

.sms-text-box > .dx-button > .dx-icon {
  width: 20px !important;
  height: 20px !important;
}
