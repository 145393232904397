.form-container {
  margin: 10px 10px 30px;
}

.long-title h3 {
  font-family: 'Segoe UI Light', 'Helvetica Neue Light', 'Segoe UI', 'Helvetica Neue', 'Trebuchet MS', Verdana;
  font-weight: 200;
  font-size: 28px;
  text-align: center;
  margin-bottom: 20px;
}

.edit_toolbar {
  padding-left: 1em;
  padding-right: 1em;
}

.form-card {
  padding: 1rem;
}